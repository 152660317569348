<template>
    <component :is="dynamicComponent" v-bind="$props" v-on="$listeners"/>
</template>

<script>
// cms-block-selector #hot-reload-debug
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

export default {
    name: `cms-block-selector`,
    extends: CmsBlock,
    computed: {
        dynamicComponent() {
            return () => import(`@/components/editor/rendering/blocks/cms-block-${this.block.type}.vue`);
        }
    }
}
</script>

<style scoped lang="scss">
</style>
